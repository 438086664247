import React, { useEffect, useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import contact from "../assets/contact.svg"

const Contact = () => {
  const form = useRef();

  const [mailSent, setMailSent] = useState(false);

  const sendEmailHandler = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_81fgz1g', 'template_8084nwt', form.current, 'lh59wvMzdGFfwjZxK')
      .then((result) => {
          console.log(result.text);
          setMailSent(true);
      }, (error) => {
          console.log(error.text);
    });
  }

  useEffect(() => {
    if(mailSent) {
      setTimeout(() => {
        setMailSent(false);
        form.current.reset();
      }, 3000);
    }
  }, [mailSent]);

  return (
   <section id="contact" className="bg-gradient x-8 py-12 mb-10">
      <div className="max-w-screen-xl mt-24 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg">
        <div className="flex flex-col justify-between">
          <div>
            <h2 className="text-4xl lg:text-5xl font-bold leading-tight font-Poppins">Lets talk about everything!</h2>
            <div className="text-gray-700 mt-8 font-Poppins">
              Hate forms? Send us an <span className="underline">email</span> instead.
            </div>
          </div>
          <div className="mt-8 text-center">
            <img src={contact} alt="" />
          </div>
        </div>
        <form name="contact" ref={form} onSubmit={sendEmailHandler}>
          <div>
            <span className="uppercase text-sm text-gray-600 font-bold">Full Name</span>
            <input id="name" name="name" className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" type="text" placeholder="" />
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm text-gray-600 font-bold">Subject</span>
            <input type="text" id="subject" name="subject" className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm text-gray-600 font-bold">Email</span>
            <input type="email" id="email" name="email" className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm text-gray-600 font-bold">Message</span>
            <textarea id="message" name="message" className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
          </div>
          <div className="mt-8">
            <button
              className="uppercase text-sm font-bold tracking-wide bg-header text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
              Send Message
            </button>
            {mailSent && <div className='px-4 mt-5 uppercase italic text-emerald-600 text-header font-Poppins tracking-wide text-center rounded-lg'>Mail sent</div>}
          </div>
        </form>
      </div>
    </section>
  )
}



export default Contact
