import about01 from "./assets/about01.svg";
import about02 from "./assets/about02.svg";
import about03 from "./assets/about03.svg";

import solutions01 from "./assets/solutions01.jpeg";
import solutions02 from "./assets/solutions02.jpg";
import solutions03 from "./assets/solutions03.jpg";

import client01 from "./assets/client01.jpg";
import client02 from "./assets/client02.png";
import client03 from "./assets/client03.jpeg";
import client04 from "./assets/client04.png";

export const aboutData = [
  {
    id: 1,
    title: "Committment to Quality",
    description:
      "Dedicated to achieving customer satisfaction through our commitment to providing reliable, maintainable and durable product on time",
    icon: about01
  },
  {
    id: 2,
    title: "World-Class Customer Service",
    description:
      "Enhancement of technical specifications and regular supply of spares and service supports.",
    icon: about02

  },
  {
    id: 3,
    title: "Experienced Technical Team",
    description:
      "Guaranteed technical support by finding the precise solutions for the critical problems.",
    icon: about03
  }
];

export const headerValues = [
  { id: 4, title: "home" },
  { id: 5, title: "about" },
  { id: 6, title: "solutions" },
  { id: 7, title: "team" },
  { id: 8, title: "clients" },
  { id: 9, title: "contact" },
];

export const solutions = [
  {
    id: 10,
    title: "Equipments",
    description: "We supply a range of new, strong and reliable forklifts.",
    image: solutions01
  },
  {
    id: 11,
    title: "Spares & Services",
    description: "We provide provide repair, overhaul, inspection, and maintenance of forklift",
    image: solutions02
  },
  {
    id: 12,
    title: "Rentals",
    description: "Order refurbished used forklifts",
    image: solutions03
  }
]

export const clients = [
  { id: 13, image: client01 },
  { id: 14, image: client02 },
  { id: 15, image: client03 },
  { id: 16, image: client04 },
];
