import React from 'react';
import collage from "../assets/collage.png";

const Hero = () => {
  return (
    <section id="home" className="px-2 py-32 bg-white md:px-0">
      <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
        <div className="flex flex-wrap items-center sm:-mx-3">
          <div className="w-full md:w-1/2 md:px-3">
            <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
              <h1 className="text-4xl font-extrabold tracking-wide text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                <span className="block xl:inline font-Poppins">Welcome to </span>
                <span className="block text-header xl:inline font-Poppins">Pragathi Forklifters</span>
              </h1>
              <p className="mx-auto text-base text-gray-800 sm:max-w-md lg:text-xl md:max-w-3xl tracking-wide">Leading Service Provider for Electric and Diesel Forklifts</p>
              <div className="flex flex-col sm:flex-row sm:space-x-4 ">
                <a href="#about" className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-header rounded-md sm:mb-0 hover:bg-[color:var(--text-color)] sm:w-auto">
                  Learn More
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 h-350px">
            <div className="w-full h-500px overflow-hidden rounded-md shadow-xl sm:rounded-xl">
                <img src={collage} alt=""/>
              </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Hero
