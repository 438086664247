import React from 'react';
import { solutions } from "../data";


const Solutions = () => {
  return (
    <section id="solutions">
      <div className="py-16 bg-white overflow-hidden">
        <div className="container m-auto px-6 space-y-8 text-gray-500 md:px-12">
            <div>
                <span className="text-lg font-semibold tracking-widest uppercase text-header">Solutions</span>
                <h2 className="mt-4 text-2xl text-gray-800 font-bold md:text-4xl tracking-widest"> Branded material handling equipments</h2>
            </div>
            <div className=" overflow-hidden sm:grid-cols-2 lg:divide-y-0 lg:grid-cols-3 xl:grid-cols-4">
            <div className="flex flex-wrap -m-4">
                {solutions.map(solution => (
                    <div className="p-4 md:w-1/3 " key={solution.id}>
                        <div className="h-full  rounded-xl shadow-cla-blue bg-gradient-to-r from-indigo-50 to-blue-50 overflow-hidden">
                            <img className=" lg:h-48 md:h-36 w-full object-cover object-center hover:transform-75 transition duration-300" src={solution.image} alt="" />
                            <div className="p-6">
                            <h1 className="title-font text-lg font-medium text-gray-600 mb-3">{solution.title}</h1>
                            <p className="leading-relaxed mb-3">{solution.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Solutions
