import React from 'react';

import avatar from "../assets/avatar.svg";

const Team = () => {
  return (
    <section id="team" className="text-gray-700 body-font border-t border-gray-200 bg-gradient">
     <div className="container px-6 py-10 mx-auto">
        <div className="xl:flex xl:items-center xL:-mx-4">
          <div className="xl:w-1/2 xl:mx-4">
            <h2 className="text-lg text-white tracking-widest font-semibold font-Poppins">OUR TEAM</h2>
            {/* <h1 className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white">Our Team</h1> */}
            <h2 className="mt-2 text-2xl text-white tracking-widest font-bold md:text-4xl font-Poppins">The Talented People Behind the Scenes of the Organization</h2>
          </div>

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-0 xl:mx-4 xl:w-1/2 md:grid-cols-2">
            <div className="pt-4 bg-white opacity-3 w-full md:w-50 justify-center items-center shadow px-3 py-4 flex flex-col">
              <img src={avatar} alt="img" title="img" className="rounded-full h-40 w-40 object-cover" />
              <h4 className="mt-8 border-b-2" id="whoobe-5f06f">Team Member 01</h4>
              <div className="mb-10 text-center capitalize" id="whoobe-m2doo">CEO</div>
            </div>

            <div className="pt-4 bg-white opacity-3 w-full md:w-50 justify-center items-center shadow px-3 py-4 flex flex-col">
              <img src={avatar} alt="img" title="img" className="rounded-full h-40 w-40 object-cover" />
              <h4 className="mt-8 border-b-2" id="whoobe-5f06f">Team Member 02</h4>
              <div className="mb-10 text-center capitalize" id="whoobe-m2doo">CEO</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
