import React from 'react';

import {aboutData} from "../data";

const About = () => {
  return (
   <section id="about" className="text-gray-700 body-font border-t border-gray-200 bg-gradient">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col  w-full mb-20">
          <h2 className="text-lg text-white tracking-widest font-semibold font-Poppins">ABOUT</h2>
          <h2 className="mt-2 text-2xl text-white tracking-widest font-bold md:text-4xl font-Poppins">Our Commitment to You</h2>
        </div>
        <div className="flex flex-wrap -m-4">
          {aboutData.map(data => (
            <div className="p-4 md:w-1/3" key={data.id}>
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-[#3dffbd] text-white flex-shrink-0">
                    <img
                    src={data.icon}
                    className="w-12 rounded-full flex-shrink-0 object-cover object-center"
                    alt=""
                  />
                  </div>
                  <h2 className="text-gray-900 text-lg title-font font-medium">{data.title}</h2>
                </div>
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">{data.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default About
