import React from 'react';
import { clients } from "../data";

const Clients = () => {
  return (
    <section id="clients">
      <div className="py-16 bg-white overflow-hidden">
        <div className="container m-auto px-6 space-y-8 text-gray-500 md:px-12">
          <div className=" overflow-hidden sm:grid-cols-2 lg:divide-y-0 lg:grid-cols-3 xl:grid-cols-4">
            <div className="flex flex-wrap ">
              <div className="max-w-sm md:max-w-4xl mx-auto grid gap-2 grid-cols-4 md:grid-cols-4">
                {clients.map(client => (
                  <div className="flex items-center justify-center py-2 col-span-2 md:col-auto" key={client.id}>
                    <img src={client.image} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clients
