import React from 'react';


const Footer = () => {
  return (
  <footer className="fixed bottom-0 bg left-0 w-full h-15" style={{background: "radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(68, 137, 130, 1) 61%, rgba(0, 191, 255, 0.47522759103641454) 100%)"}}>
    <div className="text-white text-sm tracking-widest text-center p-4 font-Poppins">
    Copyright&copy; All Rights Reserved by <span
        className="text-primary">Pragathi</span>
  </div>
  </footer>
  )
}

export default Footer
